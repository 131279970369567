import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				NEXT LEVEL ENGLISH ACADEMY
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:title"} content={"NEXT LEVEL ENGLISH ACADEMY"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.HeaderNew>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.HeaderNew>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 1rem 0px"
					color="--dark"
					text-align="center"
					font="--headline1"
					opacity="1"
					width="100% border-box"
					xl-text-transform="initial"
				>
					Services
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				margin="0px 0px .7rem 0px"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13:57:39.708Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							General English
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Our General English course is designed for students of different levels: from beginners to advanced. The course program includes the development of all language skills: speaking, listening, reading, and writing. Classes are held in the form of interactive lessons using modern teaching materials.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13:57:39.700Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/img_1559_1.jpg?v=2024-07-25T13%3A57%3A39.700Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Business English
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							The Business English course is designed for those who want to improve their language skills for working in an international environment. The program includes learning specialized vocabulary, developing negotiation skills, writing business emails, and making presentations. We will help you become confident in communicating with foreign partners.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13:57:39.717Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Preparation for International Exams
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							We offer preparation courses for international exams such as IELTS, TOEFL, Cambridge English. Our teachers have extensive experience in preparing students for these exams and know all the nuances and requirements of each. The course program includes intensive lessons, exam simulations, and individual consultations.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							English for Children and Teenagers
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							We offer English courses for children and teenagers. Our programs are designed considering the age characteristics and interests of the students. Lessons are held in the form of games, creative tasks, and interactive lessons, making learning interesting and engaging. We will help your children love the English language and achieve success in their studies.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13:57:39.714Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13%3A57%3A39.714Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Online Courses
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							We offer the opportunity to study English online. Our online courses are designed with the needs of modern students in mind, who value their time and seek convenient learning formats. You can choose between individual lessons and group classes while receiving quality education and teacher support.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Faq padding="0 0 60px 0">
			<Override slot="text1">
				Can I change the class schedule?
			</Override>
			<Override slot="text2">
				We always try to accommodate our students and make the class schedule as convenient as possible for them. If you need to change your schedule, please contact our manager, and we will try to find the most suitable option. However, please note that schedule changes are possible subject to availability in the groups.
			</Override>
			<Override slot="text3">
				What payment methods are available?
			</Override>
			<Override slot="text4">
				We offer various payment methods for the convenience of our students. You can pay for the course in cash, by bank transfer, or through online payment systems. For detailed information about payment methods, you can contact our managers or visit the "Payment" section on our website.
				<br />
				<br />
			</Override>
			<Override slot="text5">
				Do you offer trial classes?
			</Override>
			<Override slot="text6">
				Yes, we offer trial classes for all new students. This gives you the opportunity to get acquainted with our teaching methods, teachers, and the overall atmosphere of the school. A trial class helps determine your level of English proficiency and choose the most suitable course. Contact our managers to sign up for a trial class and get answers to all your questions. The trial class is free and does not oblige you to continue studying, but it will help you make an informed decision about joining our school.
				<br />
			</Override>
		</Components.Faq>
		<Components.NewFooter1>
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="link2" />
		</Components.NewFooter1>
	</Theme>;
});